import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, FormControl, MenuItem, Select } from '@mui/material';

import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import { IconMenu2 } from '@tabler/icons-react';
import useLanguage from 'hooks/useLanguage';

interface HeaderProps {
  handleLeftDrawerToggle: () => void;
  onLocaleChange?: (locale: string) => void;
  locale?: string;
}

const Header = ({ handleLeftDrawerToggle, onLocaleChange = () => {}, locale = 'th' }: HeaderProps) => {
  const theme = useTheme();
  const { locales } = useLanguage();

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: `${theme.customization.borderRadius}px`, overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* <NotificationSection /> */}
      <FormControl variant="standard" sx={{ marginX: '1rem' }}>
        <Select value={locale} label="ภาษา" onChange={(event: any) => onLocaleChange(event.target.value)}>
          {locales.map((locale) => (
            <MenuItem key={`select_locale_${locale.code}`} value={locale.code}>
              {locale.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ProfileSection />
    </>
  );
};

export default Header;
