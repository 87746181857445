import { IconBorderAll, IconUser } from '@tabler/icons-react';
import { routeLinks } from 'routes/config';

const bookmakers = {
  id: 'bookmakers',
  title: 'จัดการราคา',
  type: 'group',
  children: [
    {
      id: 'bookmakers-list',
      title: 'Providers',
      type: 'item',
      url: routeLinks.bookmakers.list,
      activeUrls: [routeLinks.bookmakers.list, routeLinks.bookmakers.create, routeLinks.bookmakers.edit, routeLinks.bookmakers.view],
      icon: IconUser,
      breadcrumbs: true
    },
    {
      id: 'odds-list',
      title: 'ราคา',
      type: 'item',
      url: routeLinks.odds.list,
      activeUrls: [routeLinks.odds.list, routeLinks.odds.create, routeLinks.odds.edit, routeLinks.odds.view],
      icon: IconBorderAll,
      breadcrumbs: true
    }
  ]
};

export default bookmakers;
