import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import AuthLayout from 'layouts/AuthLayout';
import { Navigate, RouteObject } from 'react-router-dom';
import GuestGuard from './GuestGuard';

// import view lazy load
const AuthLogin = Loadable(lazy(() => import('views/auth/login')));

const AuthRoutes: RouteObject = {
  path: '',
  element: (
    <GuestGuard>
      <AuthLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: '/',
      element: <Navigate to={routeLinks.auth.login} replace />
    },
    {
      path: routeLinks.auth.login,
      element: <AuthLogin />
    }
  ]
};

export default AuthRoutes;
