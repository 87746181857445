import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const PlayerListView = Loadable(lazy(() => import('views/players/list')));
const PlayerCreateView = Loadable(lazy(() => import('views/players/create')));
const PlayerEditView = Loadable(lazy(() => import('views/players/edit')));
const PlayerDetailView = Loadable(lazy(() => import('views/players/detail')));

const PlayerRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.players.list,
      element: <PlayerListView />
    },
    {
      path: routeLinks.players.create,
      element: <PlayerCreateView />
    },
    {
      path: routeLinks.players.edit,
      element: <PlayerEditView />
    },
    {
      path: routeLinks.players.view,
      element: <PlayerDetailView />
    }
  ]
};

export default PlayerRoutes;
