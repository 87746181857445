import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';
import Permission from './Permission';
import { ROLES } from 'config/constants';

// import view lazy load
const UserListView = Loadable(lazy(() => import('views/users/list')));
const UserCreateView = Loadable(lazy(() => import('views/users/create')));
const UserEditView = Loadable(lazy(() => import('views/users/edit')));
const UserDetailView = Loadable(lazy(() => import('views/users/detail')));
const UserResetPasswordView = Loadable(lazy(() => import('views/users/resetPassword')));

const UserRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      element: <Permission allowRoles={[ROLES.SUPERADMIN]} />,
      children: [
        {
          path: routeLinks.users.list,
          element: <UserListView />
        },
        {
          path: routeLinks.users.create,
          element: <UserCreateView />
        },
        {
          path: routeLinks.users.edit,
          element: <UserEditView />
        },
        {
          path: routeLinks.users.view,
          element: <UserDetailView />
        },
        {
          path: routeLinks.users.resetPassword,
          element: <UserResetPasswordView />
        }
      ]
    }
  ]
};

export default UserRoutes;
