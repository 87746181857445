import { IconBallFootball, IconBrandTeams, IconBuildingStadium, IconCalendar, IconShirtSport } from '@tabler/icons-react';
import { routeLinks } from 'routes/config';

const football = {
  id: 'football',
  title: 'จัดการฟุตบอล',
  type: 'group',
  children: [
    {
      id: 'leagues',
      title: 'ลีก',
      type: 'item',
      url: routeLinks.leagues.list,
      activeUrls: [routeLinks.leagues.list, routeLinks.leagues.create, routeLinks.leagues.edit, routeLinks.leagues.view],
      icon: IconBallFootball,
      breadcrumbs: true
    },
    {
      id: 'seasons',
      title: 'ฤดูกาล',
      type: 'item',
      url: routeLinks.seasons.list,
      activeUrls: [routeLinks.seasons.list, routeLinks.seasons.create, routeLinks.seasons.edit, routeLinks.seasons.view],
      icon: IconCalendar,
      breadcrumbs: true
    },
    {
      id: 'teams',
      title: 'ทีม',
      type: 'item',
      url: routeLinks.teams.list,
      activeUrls: [routeLinks.teams.list, routeLinks.teams.create, routeLinks.teams.edit, routeLinks.teams.view],
      icon: IconBrandTeams,
      breadcrumbs: true
    },
    {
      id: 'players',
      title: 'นักเตะ',
      type: 'item',
      url: routeLinks.players.list,
      activeUrls: [routeLinks.players.list, routeLinks.players.create, routeLinks.players.edit, routeLinks.players.view],
      icon: IconShirtSport,
      breadcrumbs: true
    },
    {
      id: 'stadiums',
      title: 'สนามฟุตบอล',
      type: 'item',
      url: routeLinks.stadiums.list,
      activeUrls: [routeLinks.stadiums.list, routeLinks.stadiums.create, routeLinks.stadiums.edit, routeLinks.stadiums.view],
      icon: IconBuildingStadium,
      breadcrumbs: true
    }
  ]
};

export default football;
