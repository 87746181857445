import { Typography } from '@mui/material';

import NavGroup from './NavGroup';
import menuItem from 'menus';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { IStoreState } from 'store/reducers';

const MenuList = () => {
  const { permissions, role } = useSelector((state: IStoreState) => state.auth);

  // Effect re-render filter menu item has allow permissions
  const menus = useMemo(() => {
    const recursive = (item: any) => {
      if (item.allowRoles?.length && !item.allowRoles.includes(role)) {
        return null;
      }

      if (item.allowPermissions?.length && !_.some(item.allowPermissions, (permission) => permissions.includes(permission))) {
        return null;
      }

      if (item.children?.length) {
        return { ...item, children: _.compact(item.children.map((children: any) => recursive(children))) };
      }

      return item;
    };

    // Use lodash's compact function to remove null values
    return _.compact(menuItem.items.map((item) => recursive(item)));
  }, [permissions, role]);

  const navItems = menus.map((item) => {
    if (!item.children?.length) {
      return null;
    }

    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
