import { createSlice } from '@reduxjs/toolkit';
import { fetchProfile } from './actions';
import { authLogout } from '../auth/actions';

export interface ProfileState {
  data: IProfile | null;
}

const initialState: ProfileState = {
  data: null
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.data = action.payload.data;
    });

    builder.addCase(authLogout.fulfilled, (state) => {
      state.data = null;
    });
  }
});

export const profileActions = profileSlice.actions;

export default profileSlice.reducer;
