import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const TeamListView = Loadable(lazy(() => import('views/teams/list')));
const TeamCreateView = Loadable(lazy(() => import('views/teams/create')));
const TeamEditView = Loadable(lazy(() => import('views/teams/edit')));
const TeamDetailView = Loadable(lazy(() => import('views/teams/detail')));

const TeamRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.teams.list,
      element: <TeamListView />
    },
    {
      path: routeLinks.teams.create,
      element: <TeamCreateView />
    },
    {
      path: routeLinks.teams.edit,
      element: <TeamEditView />
    },
    {
      path: routeLinks.teams.view,
      element: <TeamDetailView />
    }
  ]
};

export default TeamRoutes;
