import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const HomeView = Loadable(lazy(() => import('views/dashboard')));
const ProfileInfoView = Loadable(lazy(() => import('views/profile/info')));
const ProfileChangePasswordView = Loadable(lazy(() => import('views/profile/change-password')));

const MainRoutes: RouteObject = {
  path: '',
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),

  children: [
    {
      path: routeLinks.home,
      element: <HomeView />
    },
    {
      path: routeLinks.profile.info,
      element: <ProfileInfoView />
    },
    {
      path: routeLinks.profile.changepassword,
      element: <ProfileChangePasswordView />
    }
  ]
};

export default MainRoutes;
