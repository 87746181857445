import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer, { authWhitelistPersist, rootWhitelistPersist } from './reducers';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, createTransform } from 'redux-persist';
import * as crypto from 'services/crypto';
import { useDispatch } from 'react-redux';
import storage from 'redux-persist/lib/storage';

const cryptoTransform: any = createTransform(
  (ibs) => {
    return ibs ? crypto.encrypt(JSON.stringify(ibs)) : ibs;
  },
  (obs) => {
    return obs ? JSON.parse(crypto.decrypt(`${obs}`)) : obs;
  },
  { whitelist: authWhitelistPersist }
);

const persistConfig = {
  key: 'football-stat-backoffice-root',
  version: 1,
  reducer: rootReducer,
  storage,
  whitelist: rootWhitelistPersist,
  transforms: [cryptoTransform]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }),
  devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
