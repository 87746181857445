import { callDelete, callGet, callPost, getAxiosError } from 'services/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import authService from 'services/authentication';
import { apiUrls } from 'routes/config';

export const authLogin = createAsyncThunk('auth/login', async (payload: ILoginFormData, { rejectWithValue, fulfillWithValue }: any) => {
  try {
    const response = await callPost(apiUrls.v1.user.login, payload, { 'Content-Type': 'multipart/form-data' });

    return fulfillWithValue(response.data);
  } catch (error: any) {
    return rejectWithValue(getAxiosError(error));
  }
});

export const authLogout = createAsyncThunk('auth/logout', async (payload: any, { rejectWithValue, fulfillWithValue }: any) => {
  try {
    if (payload.force !== true) {
      await callDelete(apiUrls.v1.user.logout);
    }

    authService.delAuthorization();

    return fulfillWithValue(true);
  } catch (error: any) {
    return rejectWithValue(getAxiosError(error));
  }
});

export const fetchUserPermissions = createAsyncThunk(
  'fetch/user/permissions',
  async (payload: any, { rejectWithValue, fulfillWithValue }: any) => {
    try {
      const response = await callGet(apiUrls.v1.user.permissions);

      return fulfillWithValue(response.data);
    } catch (error: any) {
      return rejectWithValue(getAxiosError(error));
    }
  }
);
