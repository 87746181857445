import { Outlet, useLocation } from 'react-router-dom';
import { Theme, styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { IconChevronRight } from '@tabler/icons-react';
import Header from './Header';
import Sidebar from './Sidebar';
import { useState } from 'react';
import Breadcrumbs from 'components/extended/Breadcrumbs';
import breadcrumbs from 'menus/breadcrumbs';
import { useDispatch } from 'react-redux';
import { settingActions } from 'store/features/setting/slice';
import { useSelector } from 'react-redux';
import { IStoreState } from 'store/reducers';

interface MainProps {
  theme: Theme;
  open: boolean;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<MainProps>(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -(theme.customization.drawerWidth - 20),
    width: `calc(100% - ${theme.customization.drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${theme.customization.drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${theme.customization.drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));

const MainLayout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpened, setDrawerOpened] = useState(true);
  const location = useLocation();
  const dispatch = useDispatch();
  const { locale } = useSelector((state: IStoreState) => state.setting);

  const handleLeftDrawerToggle = () => {
    setDrawerOpened(!drawerOpened);
  };

  const handleLocaleChange = (locale: string) => {
    dispatch(settingActions.setLocale(locale));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: drawerOpened ? theme.transitions.create('width') : 'none'
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} onLocaleChange={handleLocaleChange} locale={locale} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={!matchDownMd ? drawerOpened : !drawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={drawerOpened}>
        <Breadcrumbs separator={IconChevronRight} data={breadcrumbs} rightAlign />
        <Outlet key={location.pathname} />
      </Main>
    </Box>
  );
};

export default MainLayout;
