import { some } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IStoreState } from 'store/reducers';

interface Props {
  allowPermissions?: string[];
  allowRoles?: any[];
}

const usePermission = ({ allowPermissions = [], allowRoles = [] }: Props) => {
  const { permissions, role } = useSelector((state: IStoreState) => state.auth);

  const canPermissionAccess = useMemo(() => {
    if (allowRoles.length && !allowRoles.includes(role)) {
      return false;
    }

    if (allowPermissions.length && !some(allowPermissions, (permission) => permissions.includes(permission))) {
      return false;
    }

    return true;
  }, [allowPermissions, allowRoles, permissions, role]);

  return { canPermissionAccess };
};

export default usePermission;
