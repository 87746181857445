import MuiAutocomplete from '@mui/material/Autocomplete';
import { useState } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

const Autocomplete = ({
  value,
  optionKey = 'value',
  optionLabel = 'display_name',
  emptyValue = false,
  options: mOptions,
  onChange,
  name,
  ...props
}: any) => {
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [options, setOptions] = useState<any[]>([]);

  const handleChange = (e: any, option: any) => {
    setSelectedOption(option);

    const event: any = new CustomEvent('ChangeEventHandler');
    Object.defineProperty(event, 'target', {
      writable: true,
      value: {
        name: name,
        value: option[optionKey]
      }
    });

    onChange(event);
  };

  useDeepCompareEffectNoCheck(() => {
    if (emptyValue) {
      const opts = mOptions ?? [];
      setOptions([{ [optionKey]: '', [optionLabel]: 'ทั้งหมด' }, ...opts]);
    } else {
      setOptions(mOptions);
    }
  }, [mOptions, emptyValue]);

  useDeepCompareEffectNoCheck(() => {
    // eslint-disable-next-line
    const defaultOption = options?.find((option: any) => option[optionKey] == value);
    if (defaultOption) {
      setSelectedOption(defaultOption);
    }
  }, [value, options]);

  return (
    <MuiAutocomplete
      {...props}
      disableClearable
      slotProps={{
        paper: {
          elevation: 3
        }
      }}
      options={options}
      value={selectedOption}
      isOptionEqualToValue={(option: any, value) => option[optionKey].toString() === value[optionKey].toString()}
      onChange={handleChange}
      getOptionKey={(option: any) => option[optionKey]}
      getOptionLabel={(option: any) => option[optionLabel]}
    />
  );
};

export default Autocomplete;
