import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const SeasonListView = Loadable(lazy(() => import('views/seasons/list')));
const SeasonCreateView = Loadable(lazy(() => import('views/seasons/create')));
const SeasonEditView = Loadable(lazy(() => import('views/seasons/edit')));
const SeasonDetailView = Loadable(lazy(() => import('views/seasons/detail')));

const SeasonRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.seasons.list,
      element: <SeasonListView />
    },
    {
      path: routeLinks.seasons.create,
      element: <SeasonCreateView />
    },
    {
      path: routeLinks.seasons.edit,
      element: <SeasonEditView />
    },
    {
      path: routeLinks.seasons.view,
      element: <SeasonDetailView />
    }
  ]
};

export default SeasonRoutes;
