import { styled, Theme, useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

interface MainProps {
  theme: Theme;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<MainProps>(({ theme }) => ({
  ...theme.typography.pageContent,
  display: 'flex',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0
}));

const PageLayout = () => {
  const theme = useTheme();

  return (
    <Main theme={theme}>
      <Outlet />
    </Main>
  );
};

export default PageLayout;
