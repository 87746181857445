const appConfig = {
  localesOption: [
    {
      label: 'ไทย',
      value: 'th'
    },
    {
      label: 'English',
      value: 'en'
    }
  ],
  timeZone: 'Asia/Bangkok'
};

export default appConfig;
