import { IconBallFootball } from '@tabler/icons-react';
import { routeLinks } from 'routes/config';

const matches = {
  id: 'matches',
  title: 'จัดการแมตช์',
  type: 'group',
  children: [
    {
      id: 'matches-list',
      title: 'ฟุตบอล',
      type: 'item',
      url: routeLinks.matches.list,
      activeUrls: [
        routeLinks.matches.list,
        routeLinks.matches.create,
        routeLinks.matches.edit,
        routeLinks.matches.view,
        routeLinks.matches.article,
        routeLinks.matches.articleEdit,
        routeLinks.matches.headToHead,
        routeLinks.matches.team,
        routeLinks.matches.odds
      ],
      icon: IconBallFootball,
      breadcrumbs: true
    }
  ]
};

export default matches;
