import { Alert, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(54, 65, 82)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    boxShadow: 'none',
    backgroundImage: 'none',
    borderRadius: '8px',
    overflow: 'hidden',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    fontSize: '4rem'
  },
  content: {
    maxWidth: '500px',
    margin: '0px auto',
    textAlign: 'center'
  },
  text: {
    margin: '0px',
    letterSpacing: '0em',
    fontWeight: 400,
    lineHeight: '1.5em',
    color: 'rgb(54, 65, 82)',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.875rem'
  }
}));

const ErrorBoundary = ({ error }: any) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.content}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h1">Something is wrong</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Alert severity="error" icon={false} className="text-center">
                    {error.message}
                  </Alert>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ErrorBoundary;
