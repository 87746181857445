import { IconFlag2, IconWorld } from '@tabler/icons-react';
import { routeLinks } from 'routes/config';

const general = {
  id: 'general',
  title: 'จัดการทั่วไป',
  type: 'group',
  children: [
    {
      id: 'countries',
      title: 'ประเทศ',
      type: 'item',
      url: routeLinks.countries.list,
      activeUrls: [routeLinks.countries.list, routeLinks.countries.create, routeLinks.countries.edit, routeLinks.countries.view],
      icon: IconWorld,
      breadcrumbs: true
    },
    {
      id: 'nationalities',
      title: 'สัญชาติ',
      type: 'item',
      url: routeLinks.nationalities.list,
      activeUrls: [
        routeLinks.nationalities.list,
        routeLinks.nationalities.create,
        routeLinks.nationalities.edit,
        routeLinks.nationalities.view
      ],
      icon: IconFlag2,
      breadcrumbs: true
    }
  ]
};

export default general;
