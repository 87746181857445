import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const LeagueListView = Loadable(lazy(() => import('views/leagues/list')));
const LeagueCreateView = Loadable(lazy(() => import('views/leagues/create')));
const LeagueEditView = Loadable(lazy(() => import('views/leagues/edit')));
const LeagueDetailView = Loadable(lazy(() => import('views/leagues/detail')));

const LeagueRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.leagues.list,
      element: <LeagueListView />
    },
    {
      path: routeLinks.leagues.create,
      element: <LeagueCreateView />
    },
    {
      path: routeLinks.leagues.edit,
      element: <LeagueEditView />
    },
    {
      path: routeLinks.leagues.view,
      element: <LeagueDetailView />
    }
  ]
};

export default LeagueRoutes;
