import { createSlice } from '@reduxjs/toolkit';
import { fetchLanguage } from './actions';

export interface SettingState extends IAppSetting {}

const initialState: SettingState = {
  locale: 'en',
  languages: [
    { code: 'en', name: 'English' },
    { code: 'th', name: 'ไทย' }
  ]
};

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchLanguage.fulfilled, (state, action) => {
      state.languages = action.payload.data;
    });
  }
});

export const settingActions = settingSlice.actions;

export default settingSlice.reducer;
