import { ButtonBase } from '@mui/material';

import Logo from 'components/Logo';

const LogoSection = () => {
  return (
    <ButtonBase disableRipple>
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;
