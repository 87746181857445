import { useRoutes } from 'react-router-dom';
import AuthenticationRoutes from './AuthenticationRoutes';
import PageRoutes from './PageRoutes';
import MainRoutes from './MainRoutes';
import UserRoutes from './UserRoutes';
import LeagueRoutes from './LeagueRoutes';
import SeasonRoutes from './SeasonRoutes';
import TeamRoutes from './TeamRoutes';
import PlayerRoutes from './PlayerRoutes';
import CountryRoutes from './CountryRoutes';
import NationalityRoutes from './NationalityRoutes';
import StadiumRoutes from './StadiumRoutes';
import MatchRoutes from './MatchRoutes';
import ArticleRoutes from './ArticleRoutes';
import BookmakerRoutes from './BookmakerRoutes';
import OddsRoutes from './OddsRoutes';
import SettingRoutes from './SettingRoutes';
import WhoscoredRoutes from './WhoscoredRoutes';

export default function ThemeRoutes() {
  return useRoutes([
    AuthenticationRoutes,
    PageRoutes,
    MainRoutes,
    UserRoutes,
    LeagueRoutes,
    SeasonRoutes,
    TeamRoutes,
    PlayerRoutes,
    CountryRoutes,
    NationalityRoutes,
    StadiumRoutes,
    MatchRoutes,
    ArticleRoutes,
    BookmakerRoutes,
    OddsRoutes,
    SettingRoutes,
    WhoscoredRoutes
  ]);
}
