import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette?.primary?.light,
  minHeight: '100vh'
}));

const AuthLayout = () => {
  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
};

export default AuthLayout;
