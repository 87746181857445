import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrls } from 'routes/config';
import { callGet, getAxiosError } from 'services/axios';

export const fetchLanguage = createAsyncThunk('languages/fetch', async (payload: any, { rejectWithValue, fulfillWithValue }: any) => {
  try {
    const response = await callGet(apiUrls.v1.languages, payload);

    return fulfillWithValue(response.data);
  } catch (error: any) {
    return rejectWithValue(getAxiosError(error));
  }
});
