import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeLinks } from './config';
import authService from 'services/authentication';
import { IStoreState } from 'store/reducers';

const GuestGuard = ({ children }: any) => {
  const { token } = useSelector((state: IStoreState) => state.auth);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (token && authService.isValidToken(token)) {
      const params = new URLSearchParams(location.search);
      const from = params.get('from') || routeLinks.home;

      navigate(from, { replace: true });
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [token]);

  return loading ? null : children;
};

export default GuestGuard;
