import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const SettingSiteListView = Loadable(lazy(() => import('views/settings/sites/list')));
const SettingSiteCreateView = Loadable(lazy(() => import('views/settings/sites/create')));
const SettingSiteEditView = Loadable(lazy(() => import('views/settings/sites/edit')));
// const SettingSiteDetailView = Loadable(lazy(() => import('views/settings/sites/detail')));

const SettingRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.settings.sites.list,
      element: <SettingSiteListView />
    },
    {
      path: routeLinks.settings.sites.create,
      element: <SettingSiteCreateView />
    },
    {
      path: routeLinks.settings.sites.edit,
      element: <SettingSiteEditView />
    }
    // {
    //   path: routeLinks.settings.sites.view,
    //   element: <SettingSiteDetailView />
    // }
  ]
};

export default SettingRoutes;
