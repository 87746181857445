import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const StadiumListView = Loadable(lazy(() => import('views/stadiums/list')));
const StadiumCreateView = Loadable(lazy(() => import('views/stadiums/create')));
const StadiumEditView = Loadable(lazy(() => import('views/stadiums/edit')));
const StadiumDetailView = Loadable(lazy(() => import('views/stadiums/detail')));

const StadiumRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.stadiums.list,
      element: <StadiumListView />
    },
    {
      path: routeLinks.stadiums.create,
      element: <StadiumCreateView />
    },
    {
      path: routeLinks.stadiums.edit,
      element: <StadiumEditView />
    },
    {
      path: routeLinks.stadiums.view,
      element: <StadiumDetailView />
    }
  ]
};

export default StadiumRoutes;
