import axios, { AxiosHeaderValue, HeadersDefaults } from 'axios';
import { AppConfig } from 'config';

interface IAxiosService {
  url: string;
  headers?: HeadersDefaults & {
    [key: string]: AxiosHeaderValue;
  };
}

const axiosService = (config: IAxiosService) => {
  const { headers } = config;

  if (headers) {
    axios.defaults.headers = { ...axios.defaults.headers, ...headers };
  }

  axios.defaults.params = {
    timezone: AppConfig.timeZone
  };

  return axios;
};

// --------- Get ---------
export const callGet = (url: string, params?: any, headers?: any) =>
  axiosService({ url, headers })
    .get(url, { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });

// --------- Post ---------
export const callPost = (url: string, data?: any, headers?: any) =>
  axiosService({ url, headers })
    .post(url, data)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

// --------- Put ---------
export const callPut = (url: string, data?: any, headers?: any) =>
  axiosService({ url, headers })
    .put(url, data)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

// --------- Patch ---------
export const callPatch = (url: string, data?: any, headers?: any) =>
  axiosService({ url, headers })
    .patch(url, data)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

// --------- Delete ---------
export const callDelete = (url: string, data?: any, headers?: any) =>
  axiosService({ url, headers })
    .delete(url, data)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });

// --------- Options ---------
export const callOptions = (url: string, headers?: any) =>
  axiosService({ url, headers })
    .options(url)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });

export const getAxiosError = (error: any) => {
  const response: any = {
    message: error.response?.data?.message ?? error.message,
    ...error.response?.data
  };

  return response;
};

export default axios;
