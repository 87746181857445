import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchLanguage } from 'store/features/setting/actions';
import { IStoreState } from 'store/reducers';
import { useAppDispatch } from 'store/store';

const useLanguage = () => {
  const dispatch = useAppDispatch();
  const { languages, locale } = useSelector((state: IStoreState) => state.setting);

  useEffect(() => {
    dispatch(fetchLanguage({}));
  }, [dispatch]);

  return { locale, locales: languages };
};

export default useLanguage;
