import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const OddsListView = Loadable(lazy(() => import('views/odds/list')));
const OddsCreateView = Loadable(lazy(() => import('views/odds/create')));
const OddsEditView = Loadable(lazy(() => import('views/odds/edit')));
const OddsDetailView = Loadable(lazy(() => import('views/odds/detail')));

const OddsRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.odds.list,
      element: <OddsListView />
    },
    {
      path: routeLinks.odds.create,
      element: <OddsCreateView />
    },
    {
      path: routeLinks.odds.edit,
      element: <OddsEditView />
    },
    {
      path: routeLinks.odds.view,
      element: <OddsDetailView />
    }
  ]
};

export default OddsRoutes;
