import { IconDashboard } from '@tabler/icons-react';
import { routeLinks } from 'routes/config';

const icons = { IconDashboard };

const dashboard = {
  id: 'home',
  title: 'หน้าหลัก',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'แดชบอร์ด',
      type: 'item',
      url: routeLinks.home,
      icon: icons.IconDashboard,
      breadcrumbs: true,
    },
  ],
};

export default dashboard;
