import { IconDeviceDesktop } from '@tabler/icons-react';
import { routeLinks } from 'routes/config';

const tasks = {
  id: 'tasks',
  title: 'Monitors',
  type: 'group',
  children: [
    {
      id: 'whoscored-list',
      title: 'Whoscored',
      type: 'item',
      url: routeLinks.whoscored.tasks.monitors.list,
      activeUrls: [routeLinks.whoscored.tasks.monitors.list, routeLinks.whoscored.tasks.monitors.view],
      icon: IconDeviceDesktop,
      breadcrumbs: true
    }
  ]
};

export default tasks;
