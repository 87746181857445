import axios from 'axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import authService from 'services/authentication';
import { authLogout } from 'store/features/auth/actions';
import { IStoreState } from 'store/reducers';
import { useAppDispatch } from 'store/store';

const Configuration = () => {
  const dispatch = useAppDispatch();
  const authState = useSelector((state: IStoreState) => state.auth);
  const { locale } = useSelector((state: IStoreState) => state.setting);

  useEffect(() => {
    axios.defaults.headers['Accept-Language'] = locale;
  }, [locale]);

  // effect set default auth token
  useEffect(() => {
    if (authState.token && authService.isValidToken(authState.token)) {
      authService.setAuthorization(authState.token);
    }
  }, [authState.token, dispatch]);

  // initial setting handle axios request and response
  useEffect(() => {
    const onLogout = () => {
      dispatch(authLogout({ force: true }));
    };

    // set axios interceptors
    authService.setAxiosInterceptors({ onLogout });
  }, [dispatch]);

  return null;
};

export default Configuration;
