import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconHome } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { routeLinks } from 'routes/config';

const useStyles = makeStyles(() => ({
  card: {
    width: '100%',
    backgroundColor: 'rgb(255, 255, 255)',
    color: 'rgb(54, 65, 82)',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    boxShadow: 'none',
    backgroundImage: 'none',
    borderRadius: '8px',
    overflow: 'hidden',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    fontSize: '4rem'
  },
  content: {
    maxWidth: '350px',
    margin: '0px auto',
    textAlign: 'center'
  },
  text: {
    margin: '0px',
    letterSpacing: '0em',
    fontWeight: 400,
    lineHeight: '1.5em',
    color: 'rgb(54, 65, 82)',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.875rem'
  }
}));

const Error404 = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const goHome = () => {
    navigate(routeLinks.home, { replace: true });
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.content}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h1" className={classes.header}>
                    404
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h1">Something is wrong</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" className={classes.text}>
                    The page you are looking was moved, removed, renamed, or might never exist!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button size="large" variant="contained" startIcon={<IconHome />} onClick={goHome} color="secondary">
                    Home
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Error404;
