import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const CountryListView = Loadable(lazy(() => import('views/countries/list')));
const CountryCreateView = Loadable(lazy(() => import('views/countries/create')));
const CountryEditView = Loadable(lazy(() => import('views/countries/edit')));
const CountryDetailView = Loadable(lazy(() => import('views/countries/detail')));

const CountryRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.countries.list,
      element: <CountryListView />
    },
    {
      path: routeLinks.countries.create,
      element: <CountryCreateView />
    },
    {
      path: routeLinks.countries.edit,
      element: <CountryEditView />
    },
    {
      path: routeLinks.countries.view,
      element: <CountryDetailView />
    }
  ]
};

export default CountryRoutes;
