import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const MatchListView = Loadable(lazy(() => import('views/matches/list')));
const MatchCreateView = Loadable(lazy(() => import('views/matches/create')));
const MatchEditView = Loadable(lazy(() => import('views/matches/edit')));
const MatchDetailView = Loadable(lazy(() => import('views/matches/detail')));
const MatchArticleView = Loadable(lazy(() => import('views/matches/atricle/detail')));
const MatchArticleEditView = Loadable(lazy(() => import('views/matches/atricle/form')));
const MatchHeadToHeadView = Loadable(lazy(() => import('views/matches/head-to-head')));
const MatchTeamView = Loadable(lazy(() => import('views/matches/team')));
const MatchOddsView = Loadable(lazy(() => import('views/matches/odds')));

const MatchRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.matches.list,
      element: <MatchListView />
    },
    {
      path: routeLinks.matches.create,
      element: <MatchCreateView />
    },
    {
      path: routeLinks.matches.edit,
      element: <MatchEditView />
    },
    {
      path: routeLinks.matches.view,
      element: <MatchDetailView />
    },
    {
      path: routeLinks.matches.article,
      element: <MatchArticleView />
    },
    {
      path: routeLinks.matches.articleEdit,
      element: <MatchArticleEditView />
    },
    {
      path: routeLinks.matches.headToHead,
      element: <MatchHeadToHeadView />
    },
    {
      path: routeLinks.matches.team,
      element: <MatchTeamView />
    },
    {
      path: routeLinks.matches.odds,
      element: <MatchOddsView />
    }
  ]
};

export default MatchRoutes;
