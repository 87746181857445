import { useMemo } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

import { IconTallymark1 } from '@tabler/icons-react';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';

const linkSX = {
  display: 'flex',
  color: 'grey.900',
  textDecoration: 'none',
  alignContent: 'center',
  alignItems: 'center'
};

interface BreadcrumbsProps {
  data: any[];
  card?: boolean;
  divider?: boolean;
  icon?: boolean;
  icons?: boolean;
  maxItems?: number;
  rightAlign?: boolean;
  separator?: any;
}

const Breadcrumbs = ({ data, card, divider, icons, maxItems, rightAlign, separator, ...others }: BreadcrumbsProps) => {
  const theme = useTheme();
  const location = useLocation();

  const iconStyle = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: '1rem',
    height: '1rem',
    color: theme.palette.secondary.main
  };

  const findBreadcrumbs = useMemo(() => {
    const memoizedFindBreadcrumbs = (breadcrumbs: any[], url: string): any[] => {
      for (const breadcrumb of breadcrumbs) {
        if (breadcrumb.url && matchPath({ path: breadcrumb.url }, url)) {
          const newBreadcrumb = {
            ...breadcrumb,
            url: location.pathname
          };
          return [newBreadcrumb];
        }

        if (breadcrumb.children?.length > 0) {
          const foundInChildren = memoizedFindBreadcrumbs(breadcrumb.children, url);
          if (foundInChildren?.length > 0) {
            return [breadcrumb, ...foundInChildren];
          }
        }
      }

      return [];
    };

    return memoizedFindBreadcrumbs;

    // eslint-disable-next-line
  }, [location.pathname]);

  const currentBreadcrumbs = findBreadcrumbs(data, location.pathname);

  if (!currentBreadcrumbs.length) {
    return null;
  }

  // item separator
  const SeparatorIcon = separator;
  const separatorIcon = separator ? <SeparatorIcon stroke={1.5} size="1rem" /> : <IconTallymark1 stroke={1.5} size="1rem" />;

  return (
    <Card
      sx={{
        marginBottom: card === false ? 0 : theme.spacing(3),
        border: 'none',
        borderColor: theme.palette.primary[200]! + 75,
        background: card === false ? 'transparent' : theme.palette.background.default
      }}
      {...others}
    >
      <Box sx={{ p: 2, pl: card === false ? 0 : 2 }}>
        <Grid
          container
          direction={rightAlign ? 'row' : 'column'}
          justifyContent={rightAlign ? 'space-between' : 'flex-start'}
          alignItems={rightAlign ? 'center' : 'flex-start'}
          spacing={1}
        >
          <Grid item>
            <MuiBreadcrumbs
              sx={{ '& .MuiBreadcrumbs-separator': { width: 16, ml: 1.25, mr: 1.25 } }}
              aria-label="breadcrumb"
              maxItems={maxItems || 8}
              separator={separatorIcon}
            >
              {currentBreadcrumbs?.map((item: any, key) => {
                const CollapseIcon = item.icon ? item.icon : AccountTreeTwoToneIcon;
                return (
                  <Typography key={key} component={item.url ? Link : Typography} to={item.url} variant="subtitle1" sx={linkSX}>
                    {icons && <CollapseIcon style={iconStyle} />}
                    {item.title}
                  </Typography>
                );
              })}
            </MuiBreadcrumbs>
          </Grid>
        </Grid>
      </Box>
      {card === false && divider !== false && <Divider sx={{ borderColor: theme.palette.primary.main, mb: 3 }} />}
    </Card>
  );
};

export default Breadcrumbs;
