import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const BookmakerListView = Loadable(lazy(() => import('views/bookmakers/list')));
const BookmakerCreateView = Loadable(lazy(() => import('views/bookmakers/create')));
const BookmakerEditView = Loadable(lazy(() => import('views/bookmakers/edit')));
const BookmakerDetailView = Loadable(lazy(() => import('views/bookmakers/detail')));

const BookmakerRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.bookmakers.list,
      element: <BookmakerListView />
    },
    {
      path: routeLinks.bookmakers.create,
      element: <BookmakerCreateView />
    },
    {
      path: routeLinks.bookmakers.edit,
      element: <BookmakerEditView />
    },
    {
      path: routeLinks.bookmakers.view,
      element: <BookmakerDetailView />
    }
  ]
};

export default BookmakerRoutes;
