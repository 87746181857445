import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const ArticleListView = Loadable(lazy(() => import('views/articles/list')));
const ArticleCreateView = Loadable(lazy(() => import('views/articles/create')));
const ArticleEditView = Loadable(lazy(() => import('views/articles/edit')));
const ArticleDetailView = Loadable(lazy(() => import('views/articles/detail')));

const ArticleRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.articles.list,
      element: <ArticleListView />
    },
    {
      path: routeLinks.articles.create,
      element: <ArticleCreateView />
    },
    {
      path: routeLinks.articles.edit,
      element: <ArticleEditView />
    },
    {
      path: routeLinks.articles.view,
      element: <ArticleDetailView />
    }
  ]
};

export default ArticleRoutes;
