import { ThemeProvider, StyledEngineProvider, CssBaseline } from '@mui/material';
import Routes from 'routes';
import themes from 'themes';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ReduxProvider } from 'store/ReduxProvider';
import Configuration from 'components/Configuration';
import 'moment/locale/th';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary as ErrorPage } from './views/errors';

const configTheme = {
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  drawerWidth: 260
};

function App() {
  useEffect(() => {
    document.title = process.env.REACT_APP_NAME ?? 'Backoffice';
  }, []);

  return (
    <ReduxProvider>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(configTheme)}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="th">
              <SnackbarProvider
                dense
                maxSnack={3}
                autoHideDuration={3000}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                classes={{
                  containerAnchorOriginTopRight: 'container-top-right'
                }}
              >
                <ErrorBoundary FallbackComponent={ErrorPage}>
                  <CssBaseline />
                  <Configuration />
                  <Routes />
                </ErrorBoundary>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
