import { createSlice } from '@reduxjs/toolkit';
import { authLogin, authLogout, fetchUserPermissions } from './actions';
import { fetchProfile } from '../profile/actions';

type RoleData = {
  id: number;
  name: string;
};

export interface AuthState {
  id: number | null;
  username: string | null;
  token: string | null;
  isSubmitting: boolean;
  permissions: string[];
  role: number | null;
  role_data: RoleData | null;
}

const initialState: AuthState = {
  id: null,
  username: null,
  token: null,
  isSubmitting: false,
  permissions: [],
  role: null,
  role_data: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // action login
    builder.addCase(authLogin.pending, (state) => {
      state.isSubmitting = true;
    });
    builder.addCase(authLogin.fulfilled, (state, action) => {
      const data = action.payload.data;
      state.id = data.id;
      state.token = data.token;
      state.username = data.username;
      state.isSubmitting = false;
      state.permissions = [];
      state.role = data.role;
      state.role_data = data.role_data;
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.role = action.payload.data.role;
    });

    // action logout
    builder.addCase(authLogout.pending, () => {});
    builder.addCase(authLogout.fulfilled, (state) => {
      state.token = null;
      state.username = null;
      state.isSubmitting = false;
      state.permissions = [];
    });

    // action fetch permissions
    builder.addCase(fetchUserPermissions.pending, () => {});
    builder.addCase(fetchUserPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload.data;
    });
  }
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
