import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

const consoleError = console.error;
const consoleWarn = console.warn;
const SUPPRESSED_ERRORS = ['Support for defaultProps will be removed', 'findDOMNode is deprecated and will be removed'];
const SUPPRESSED_WARNS = ['You have provided an out-of-range value'];

console.error = function filterErrors(msg, ...args) {
  if (!SUPPRESSED_ERRORS.some((entry) => msg.includes(entry))) {
    consoleError(msg, ...args);
  }
};

console.warn = function filterWarns(msg, ...args) {
  if (!SUPPRESSED_WARNS.some((entry) => msg.includes(entry))) {
    consoleWarn(msg, ...args);
  }
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
