import { callGet, callPut, getAxiosError } from 'services/axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrls } from 'routes/config';

export const fetchProfile = createAsyncThunk('fetch/profile', async (payload: any, { rejectWithValue, fulfillWithValue }: any) => {
  try {
    const response = await callGet(apiUrls.v1.user.info);

    return fulfillWithValue(response.data);
  } catch (error: any) {
    return rejectWithValue(getAxiosError(error));
  }
});

export const submitChangePassword = createAsyncThunk(
  'user/changePassword',
  async (payload: IChangePasswordFormData, { rejectWithValue, fulfillWithValue }: any) => {
    try {
      const response = await callPut(apiUrls.v1.user.changePassword, payload, { 'Content-Type': 'multipart/form-data' });

      return fulfillWithValue(response.data);
    } catch (error: any) {
      return rejectWithValue(getAxiosError(error));
    }
  }
);
