import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  ['link'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],

  ['clean'] // remove formatting button
];

interface Props {
  name: string;
  value: string;
  disabled?: boolean;
  onChange: (e: any) => void;
}

const TextEditor: React.FC<Props> = ({ name, onChange, disabled = false, ...props }) => {
  const [value, setValue] = useState(props.value);

  const handleChange = (text: string) => {
    setValue(text);

    const event: any = new CustomEvent('ChangeEventHandler');
    Object.defineProperty(event, 'target', {
      writable: true,
      value: {
        name: name,
        value: text
      }
    });

    onChange(event);
  };

  useEffect(() => {
    setValue(props.value);

    // eslint-disable-next-line
  }, [name]);

  return (
    <ReactQuill
      theme="snow"
      bounds=".editor-container"
      modules={{
        toolbar: toolbarOptions
      }}
      value={value}
      onChange={handleChange}
      readOnly={disabled}
    />
  );
};

export default TextEditor;
