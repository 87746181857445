import { Navigate, Outlet } from 'react-router-dom';
import { routeLinks } from './config';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import usePermission from 'hooks/usePermission';
import { fetchUserPermissions } from 'store/features/auth/actions';
import { useAppDispatch } from 'store/store';
import { fetchProfile } from 'store/features/profile/actions';

interface Props {
  allowPermissions?: string[];
  allowRoles?: any[];
  redirectUrl?: string;
}

const Permission = ({ redirectUrl, allowPermissions, allowRoles }: Props) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isFetchingRole, setIsFetchingRole] = useState(true);
  const { canPermissionAccess } = usePermission({ allowPermissions, allowRoles });

  // Effect fetch user permissions
  useEffect(() => {
    dispatch(fetchUserPermissions({})).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        setIsLoading(false);
      } else {
        enqueueSnackbar(response.payload?.message ?? 'Error fetch permissions failed', { variant: 'error' });
      }
    });

    dispatch(fetchProfile({})).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        setIsFetchingRole(false);
      } else {
        enqueueSnackbar(response.payload?.message ?? 'Error fetch roles failed', { variant: 'error' });
      }
    });
  }, [dispatch]);

  // return null is fetching permissions
  if (isLoading || isFetchingRole) {
    return null;
  }

  // check allow permissions
  if (!canPermissionAccess) {
    return <Navigate to={redirectUrl || routeLinks.home} replace />;
  }

  // return children
  return <Outlet />;
};

export default Permission;
