import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const WhoscoredTaskListView = Loadable(lazy(() => import('views/whoscored/tasks/list')));
const WhoscoredTaskDetailView = Loadable(lazy(() => import('views/whoscored/tasks/detail')));

const WhoscoredRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.whoscored.tasks.monitors.list,
      element: <WhoscoredTaskListView />
    },
    {
      path: routeLinks.whoscored.tasks.monitors.view,
      element: <WhoscoredTaskDetailView />
    }
  ]
};

export default WhoscoredRoutes;
