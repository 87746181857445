import React, { forwardRef, useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

interface NavCollapseProps {
  item: any;
  level: number;
}

const NavItem = ({ item, level }: NavCollapseProps) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState(false);

  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: isActive ? 8 : 6,
        height: isActive ? 8 : 6
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: any = {
    component: forwardRef((props, ref: any) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} reloadDocument={item.reloadDocument} />
    ))
  };

  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  // active menu item on page load
  useEffect(() => {
    const matchingRoute = item.activeUrls?.find((pattern: string) => matchPath(pattern, pathname));
    if (document.location.pathname === item.url || matchingRoute) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${theme.customization.borderRadius}px`,
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`
      }}
      selected={isActive}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
      <ListItemText
        primary={
          React.isValidElement(item.title) ? (
            item.title
          ) : (
            <Typography variant={isActive ? 'h5' : 'body1'} color="inherit">
              {item.title}
            </Typography>
          )
        }
        secondary={
          React.isValidElement(item.caption) ? (
            item.caption
          ) : (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
