import { lazy } from 'react';
import { Loadable } from 'components';
import { routeLinks } from './config';
import MainLayout from 'layouts/MainLayout';
import { RouteObject } from 'react-router-dom';
import AdminGuard from './AdminGuard';

// import view lazy load
const NationalityListView = Loadable(lazy(() => import('views/nationalities/list')));
const NationalityCreateView = Loadable(lazy(() => import('views/nationalities/create')));
const NationalityEditView = Loadable(lazy(() => import('views/nationalities/edit')));
const NationalityDetailView = Loadable(lazy(() => import('views/nationalities/detail')));

const NationalityRoutes: RouteObject = {
  element: (
    <AdminGuard>
      <MainLayout />
    </AdminGuard>
  ),
  children: [
    {
      path: routeLinks.nationalities.list,
      element: <NationalityListView />
    },
    {
      path: routeLinks.nationalities.create,
      element: <NationalityCreateView />
    },
    {
      path: routeLinks.nationalities.edit,
      element: <NationalityEditView />
    },
    {
      path: routeLinks.nationalities.view,
      element: <NationalityDetailView />
    }
  ]
};

export default NationalityRoutes;
