import { lazy } from 'react';
import { Loadable } from 'components';
import { RouteObject } from 'react-router-dom';
import PageLayout from 'layouts/PageLayout';

const Error404 = Loadable(lazy(() => import('views/errors/404')));

const PageRoutes: RouteObject = {
  path: '',
  element: <PageLayout />,
  children: [
    {
      path: '*',
      element: <Error404 />
    }
  ]
};

export default PageRoutes;
