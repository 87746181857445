import { IconUsers } from '@tabler/icons-react';
import { ROLES } from 'config/constants';
import { routeLinks } from 'routes/config';

const users = {
  id: 'users',
  title: 'จัดการผู้ใช้งาน',
  type: 'group',
  allowRoles: [ROLES.SUPERADMIN],
  children: [
    {
      id: 'users-list',
      title: 'ผู้ใช้งาน',
      type: 'item',
      url: routeLinks.users.list,
      activeUrls: [
        routeLinks.users.list,
        routeLinks.users.create,
        routeLinks.users.edit,
        routeLinks.users.view,
        routeLinks.users.resetPassword
      ],
      icon: IconUsers,
      breadcrumbs: true
    }
  ]
};

export default users;
